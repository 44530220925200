.gridFormClienteExterno {
    margin-top: 20px !important; 

    padding-left: 20px !important;
    padding-right: 20px !important;
}

.gridFormClienteInterno {
    height: 100% !important;

    background-color: #FFF !important;
    
    border-radius: 20px !important;
    border-style: solid;
    border-color: #E0E0E0 !important;
    border-width: 2px !important;

    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

@media (max-width:991px) {
    .gridFormClienteExterno {
        margin-bottom: 20px !important;
    }
}

.formularioCliente {
    margin-top: 40px !important;
}

.icon-spin {
-webkit-animation: icon-spin 2s infinite linear;
        animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
}
}

@keyframes icon-spin {
0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
}
}

.scrollImg {
    order: 1;
    flex: 0 1 auto;
    align-self: flex-end;
}

.scrollContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}