ul.social li {
    padding: 3px 0;
}

ul.social li a i {
    margin-right: 5px;
    font-size: 25px;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}

ul.social li:hover a i {
    font-size: 30px;
    margin-top: -10px;
}

ul.social li a, ul.quick-links li a {
    color: #ffffff;
}

ul.social li a:hover {
    color: #eeeeee;
}

ul.quick-links li {
    padding: 3px 0;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}

ul.quick-links li:hover {
    padding: 3px 0;
    margin-left: 5px;
    font-weight: 700;
}

ul.quick-links li a i {
    margin-right: 5px;
}

ul.quick-links li:hover a i {
    font-weight: 700;
}

.list-inline-item {
    display: inline-block;
}

.tableFormat {
    padding: 0px;
    border-spacing: 0px;
}

.maxWidth600 {
    max-width: 600px;
}

.divMain {
    background-color: #f4f4f4; 
    margin: 0 !important; 
    padding: 0 !important;
    height: 100vh;
}

.tdStyle1 {
    padding: 40px 10px 40px 10px;
}

.tdStyle2 {
    padding: 0px 10px 0px 10px;
}

.tdStyle3 {
    padding: 40px 20px 20px 20px !important;
    border-radius: 4px 4px 0px 0px;
    color: #111111;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 4px;
    line-height: 48px;
}

.tdStyle4 {
    padding: 20px 30px 40px 30px;
    color: #666666;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
}

.tdStyle5 {
    padding: 0px 30px 0px 30px;
    color: #666666;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
}

.tdStyle6 {
    padding: 15px 30px 40px 30px;
    border-radius: 0px 0px 4px 4px;
    color: #666666;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
}

.tdStyle7 {
    padding: 0px 10px 0px 10px;
}

.tdStyle8 {
    padding: 30px 10px 0px 10px;
}

.tdStyle9 {
    padding: 30px 30px 30px 30px;
    border-radius: 4px 4px 4px 4px;
    color: #666666;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
}

.h1Style {
    display: block;
    border: 0px;
}

.h1Style2 {
    font-size: 48px;
    font-weight: 400;
    margin: 2px;
}

.h2Style {
    font-size: 20px;
    font-weight: 400;
    color: #111111;
    margin: 0;
}

.ulStyle {
    padding-left: 0px;
}

.corLaranja {
    color: #FFA73B;
}

.margin0 {
    margin: 0;
}